import React from 'react';
import AvatarRed from '../assets/valtershoes.png';
import RedArrowLine from '../assets/redarrowline.png';
import SectionThreeBg from '../assets/sectionthreebg.png';
import RedTriangle from '../assets/redtriangle.png';
import RedUnder from '../assets/redunder.png';
import SectionThreeFigure from '../assets/sectionthreefigure.png';



export default function Section3() {
  return (
    <div id="katarinavalterovic" className='section sectionthree' style={{background:`url(${SectionThreeBg})`}}>
      <div className="d-block d-lg-flex section__container">
        <div className="position-relative left section__redmargine">
          <img className='section__avatar position-relative' src={AvatarRed} alt="" style={{zIndex:2}} />
          <img src={RedUnder} alt="" className="section__under position-absolute" style={{zIndex:0}} />
        </div>
        <div className="position-relative right">
          <div className="section__title d-flex">
            <h2 className='red-text'>Валтер<br />Шуз</h2>
            <div className='section__figure d-none d-lg-block'>
              <img src={SectionThreeFigure} alt="" />
            </div>
          </div>
          <p className="section__description red-text frubik">
            Испред бренда „Valtershoes” на инстаграм профилу стоји опис – handmade shoes, designed and crafted in Belgrade. Иза овог бренда налази се млада дизајнерка и предузетница Катарина Валтеровић Змијанац, која је своју жељу из студентских дана претворила у бизнис
          </p>
          <div className="section__button position-relative">
            <button className='blue-bg'><a href="/valtershoes">ПРОЧИТАЈ ВИШЕ</a></button>
            <img className='section__triangle-red position-absolute' src={RedTriangle} alt="" />
            <img className='position-absolute section__under-red' src={RedArrowLine} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
