import React from 'react';
import Fb from '../assets/fbf.png';
import Yt from '../assets/ytf.png';
import In from '../assets/inf.png';
import Ministarstvo from '../assets/ministarstvo.png';
import People from '../assets/people.png';
import FooterBg from '../assets/footer.jpg';
import Logo from '../assets/logo.png';




const Footer = () => {
    return (
        <div className='footer d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-stretch px-5' style={{background:`url(${FooterBg})`}}>
            <div className='footer__logo'>
                <a href="/">
                    <img src={Logo} alt="" />
                </a>
            </div>
            <div className='footer__ministarstvo'>
                <a href="https://inovacije.gov.rs/" target="_blank">
                    <img src={Ministarstvo} alt="" />
                </a>
            </div>
            <div className='footer__social'>
                <div className="d-flex align-items-center">
                    <a href="https://www.instagram.com/znamdamogu/" target="_blank">
                        <img src={In} alt="" style={{position:'relative', top:'17px'}} />
                    </a>
                    <a href="https://www.facebook.com/projekatznamdamogu/" target="_blank">
                        <img src={Fb} alt="" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCGd1xMzYr82F_zvrfXtrz3w/featured" target="_blank">
                        <img src={Yt} alt="" style={{position:'relative', top:'7px'}} />
                    </a>
                </div>
                <img className='mt-auto' src={People} alt="" />
            </div>
        </div>
     );
}

export default Footer;