import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineRedOne from '../../assets/lineredfirst.png';
import LineRedTwo from '../../assets/lineredsecond.png';
import MenuClose from '../../assets/menuclose.png';
import Play from '../../assets/play.png';
import Sestre from '../../assets/sestrevitanov.jpg'


export default function SestreVitanov() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={Sestre} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineRedOne} alt="" />
          <img src={Play} alt="" className='position-absolute playbutton' />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center blue-text border-blue position-relative'>
                <span className="article__title--linered position-absolute"></span>
                Поезија на кожи
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-0 red-text px-2">
              Иза бренда „Сестре Витанов” стоје Данијела и Марина, оне су трећа генерација у породици која се бави производњом накита. У промеру чега је више, уметности или занатства, рекло би се да уметност иде корак испред. Не зато што су им чекићи и остали фини алати страни, већ због слободе која је њихова основна стваралачка водиља, а зна се кога она води и, пре свега, инспирише. <br /><br />
            </p>
            <p className='text-center mb-0 red-text px-2'>
              Да је амбијент одрастања важан стваралачки фактор, потврда је из више предузетничких прича. И није ствар у „гледати” и „красти занат”, изгледа да је читава цака упити, удахнути стваралаштво. Томе заиста сведоче и сестре Витанов које су још из времена када су биле девојчице „вириле” изнад пулта у породичној радњи.
Породичан бизнис подразумева да је породица ту, и то буквално – управо тако су и дошле до првог џепарца. То што долазе из приче прекаљених произвођача накита била им је одскочна даска, а не баласт и терет, како су многи то умели да доживе. Ипак, од те „даске” одскочиле су до данас много, напомиње Марина. <br /><br />

            </p>
            <p className='red-text text-center px-2'>
              Накит који су гледале у детињству, који су креирали и стварали њихов деда, па после и отац, био је одраз оновременске класике и софистицираности. Ни сестра ни она сама нису биле сигурне да ли је то нешто што потенцијално може да буде лице њиховог идентитета. Размишљале су и о томе да та прича, можда, и није за њих.
Али како породични посао некако увек иде са тобом и „не можеш га закључати у канцеларији”, идеја је наставила да их прати, можда прецизније, није их напуштала, а то је, испоставиће се, било сасвим довољно. Хоће ли успети да се одлепе од класичног обрасца израде, а да притом остану верне бренду, било је главно питање које их је мучило. <br /><br />
Међутим, убрзо се стидљиво појавила идеја – „зашто не бисмо пробале нешто старо, а ново, нешто на наш начин. Када су прве идеје биле реализоване, стигла су и прва одушевљења – хеј, некоме се допада то што радимо. Испоставило се да је број таквих клијената заправо позамашан. Највећа је сатисфакција када видиш да то што си направио некоме значи, да си неком сећање, а некоме диван тренутак, то је оно што нас покреће. Наша највећа идеја је остати доследан слободи”. По принципу „no one is you and that is your power”, Марина истиче да је најважније бити веран себи, јер како каже, онда конкуренција заправо и не постоји. <br /><br />
„Могле бисмо дати некој инфлуенсерки да носи наш накит, али то једноставно не бисмо биле ми, то није наша ниша”, истиче Марина. Зато, саме воде инстаграм страницу која броји преко тридесет хиљада пратилаца. Фотографије су праћене поезијом и описују накит прецизније него што би било који други контент. Све је минималистичко, нежно и фино, а истовремено снажно упадљиво и класично елегантно. Дизајн, софистицираност, боје накита... пожелиш да их додирнеш, да буду твоји док замишљаш мирис парфема на зглобу на ком је баш та наруквица.

            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineRedTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/dw5M8KvfUd4?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
