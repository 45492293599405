import React from 'react';
import {ProductConsumer} from '../../context/context';
import styled from 'styled-components';
import About from '../../components/About';
import { Button } from 'reactstrap';
import Section1 from '../../components/Section1';
import Section2 from '../../components/Section2';
import Section3 from '../../components/Section3';
import Section4 from '../../components/Section4';
import Section5 from '../../components/Section5';
import Section6 from '../../components/Section6';
import Section7 from '../../components/Section7';
import Section8 from '../../components/Section8';
import Header from '../../components/Header';



const HomePageTemplate = () => {
    return (
        <>
            <Header />
            <About />
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <Section7 />
            <Section8 />
        </>
     );
}

const HomeWrapper = styled.section`
    background:rgba(95,183,234,0.5);
    .service-icon {
        font-size: 2.5rem;
        color:var(--primaryColor);
    }
    p {
        color: var(--darkGrey);
    }
`


export default HomePageTemplate;