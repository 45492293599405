
import React, { Component } from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/HomePage/HomePageTemplate';
import ArticleBlue from './pages/SubPages/ArticleBlue';
import ArticleRed from './pages/SubPages/ArticleRed';
import ChanyMaria from './pages/SubPages/pricaIzRukava';
import FashionFondHouse from './pages/SubPages//FashionFondHouse';
import ValterShoes from './pages/SubPages/ValterShoes';
import Njegomir from './pages/SubPages/NjegomirTorte';
import RadionicaLepote from './pages/SubPages/RadionicaLepote';
import RobertoBaressi from './pages/SubPages/RobertoBarezi';
import SestreVitanov from './pages/SubPages/SestreVitanov';
import ZvrljanjeSrcem from './pages/SubPages/ZvrljanjeSrcem'


import './App.scss';
import {Route, Switch} from 'react-router-dom';
import "bootstrap/scss/bootstrap.scss";


class App extends Component {
  render() {
    return <>
      <Switch>
        <Route  path="/" exact component={Home} />
        <Route  path="/blue"component={ArticleBlue} />
        <Route  path="/red" component={ArticleRed} />
        <Route  path="/chanymaria"  component={ChanyMaria} />
        <Route  path="/fondfashionhouse"  component={FashionFondHouse} />
        <Route  path="/valtershoes"  component={ValterShoes} />
        <Route  path="/njegomir"  component={Njegomir} />
        <Route  path="/radionicalepote"  component={RadionicaLepote} />
        <Route  path="/robertobaressi"  component={RobertoBaressi} />
        <Route  path="/sestrevitanov"  component={SestreVitanov} />
        <Route  path="/zvrljanjesrcem"  component={ZvrljanjeSrcem} />
      </Switch>
       <Footer />
    </>
  }
}


   export default App;
