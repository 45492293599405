import React from 'react';
import AvatarRed from '../assets/radionicalepote.png';
import RedArrowLine from '../assets/redarrowline.png';
import SectionFiveBg from '../assets/sectiontwobg.png';
import RedTriangle from '../assets/redtriangle.png';
import RedUnder from '../assets/redunder.png';
import SectionFiveFigure from '../assets/sectionfivefigure.png';



export default function Section5() {
  return (
    <div id="snezanalatic" className='section sectionfive' style={{background:`url(${SectionFiveBg})`}}>
      <div className="d-block d-lg-flex section__container">
        <div className="position-relative left section__redmargine">
          <img className='section__avatar position-relative' src={AvatarRed} alt="" style={{zIndex:2}} />
          <img src={RedUnder} alt="" className="section__under position-absolute" style={{zIndex:0}} />
        </div>
        <div className="position-relative right">
          <div className="section__title d-flex">
            <h2 className='red-text'>Лепота на дуге стазе</h2>
            <div className='section__figure d-none d-lg-block'>
              <img src={SectionFiveFigure} alt="" />
            </div>
          </div>
          <p className="section__description red-text frubik">
            Витална кожа лица је, заиста, важна људима. Методе које је обезбеђују су постале инстант, више него икада. Ипак, неке beauty приче су другачије, играју на дуге стазе, таква је „Радионица лепоте”.
          </p>
          <div className="section__button position-relative">
            <button className='blue-bg'><a href="/radionicalepote">ПРОЧИТАЈ ВИШЕ</a></button>
            <img className='section__triangle-red position-absolute' src={RedTriangle} alt="" />
            <img className='position-absolute section__under-red' src={RedArrowLine} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}