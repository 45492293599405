import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineBlueOne from '../../assets/linebluefirst.png';
import LineBlueTwo from '../../assets/linebluesecond.png';
import MenuClose from '../../assets/menuclose.png';
import Play from '../../assets/play.png';
import Dragana from '../../assets/draganamilanovic.jpg'


export default function RobertoBarezi() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

   useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={Dragana} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineBlueOne} alt="" />
          <img src={Play} alt="" className='position-absolute playbutton' />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center red-text border-red position-relative'>
                <span className="article__title--lineblue position-absolute"></span>
                Од сумрака до свитања
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-0 red-text px-2">
              Roberto Baressi је бренд који се бави производњом одеће и аксесоара. Међутим, да ли бисте након онлине поручене кошуље помислили да купујете од домаћег бренда? Не. Ни ми. <br /><br />
            </p>
            <p className='text-center mb-0 red-text px-2'>
              Драгана Милановић са својом сестром, показала је на терену шта конкретно значи и шта све може да оствари – girl’s power. Данас је власница бренда и заљубљеник у свој посао, али за њен развојни пут може се рећи све друго осим да је био праволинијски. Завршила је италијански језик на Филолошком факултету, али већ на половини студирања, било јој је јасно да то није оно чиме у будућности жели да се бави. Roberto Baressi је још деведесетих основао њен отац, и примарна делатност била је производња кошуља. Међутим, стицај животних околности ставио је бизнис на дубоки sleep mood, фирма је постојала, али то је било све. <br /><br />
            </p>
            <p className='red-text text-center px-2'>
              За то време, Драгана тражи свој пут покушавајући да се запосли у некој од страних корпорација које су тек почеле да се појављују. Налази посао у сјајној фирми, али проблем убрзо постаје јасан – не жели тиме да се бави. И десило се оно што се обично догађа са свим пузлама, сви делови су на столу, питање је само: умеш ли да видиш ширу слику? Драгана ју је видела овако – имам бренд са традицијом, делимичну логистику, сестру партнера, Италију у малом прсту и јасан приказ преузет из фирме у којој је радила – како здрав колектив треба тачно да изгледа.  Инспирисана „чизмом”, решила је да проба да заокружи све, и производњу кошуља храбро претвори у ширу причу, у комплетну производњу одеће и аксесоара. Оно што их је издвојило на тржишту, и то веома, је намера да све то раде доста квалитетно! Пажљиво бирани материјали, одмерен дизајн, кокетирање са раскошима Италије, богатство колора, а опет шмек минимализма у финишу, ставили су Roberto Baressi на посебно место – на своје место. <br /><br />
На питање шта сматра пресудним у вођењу бизниса, истакла је да је реч о споју више ствари, најпре, да оно што радиш мора „да те вози”, да нема савршеног курса, и да се проблеми морају решавати у ходу. Да је важно окружити се оптимистима, а не „саботерима”, и да уколико желиш да се бавиш бизнисом мораш непрестано да се едукујеш, а како каже, знање никада није било доступније. Зато, у колима не певуши, већ радо слуша подкасте. И најважније за крај, трансформиши се када је потребно, тако да кад корона, на пример, каже онлајн, ти уведеш онлајн.

            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineBlueTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3d1ZvMuwzaY?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
