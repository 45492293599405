import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineRedOne from '../../assets/lineredfirst.png';
import LineRedTwo from '../../assets/lineredsecond.png';
import MenuClose from '../../assets/menuclose.png';
import Play from '../../assets/play.png';
import Snezana from '../../assets/snezanalatic.jpg'


export default function RadionicaLepote() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={Snezana} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineRedOne} alt="" />
          <img src={Play} alt="" className='position-absolute playbutton' />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center blue-text border-blue position-relative'>
                <span className="article__title--linered position-absolute"></span>
                Лепота на дуге стазе
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-0 red-text px-2">
              Витална кожа лица је, заиста, важна људима. Методе које је обезбеђују су постале инстант, више него икада. Ипак, неке beauty приче су другачије, играју на дуге стазе, таква је „Радионица лепоте”. <br /><br />
            </p>
            <p className='text-center mb-0 red-text px-2'>
              Снежана Латић, власница је салона „Радионица лепоте” и акредитовани носилац „Facial gym”, технике за обликовање и дефиницију лица. Козметолог, едукаторка и предузетница којој је пошло за руком да опстане двадесет година у beauty индустрији и постане зачетник једне нове методе неге на овим просторима. <br /><br />
„Чувеном” маркетинг методом „од уста до уста” и са, како каже, нула динара уложеним у исти, стигла је до многобројних клијената и данас вишегодишњом сарадњом! <br /><br />

            </p>
            <p className='red-text text-center px-2'>
              Када сам почела са 19. година, нисам знала шта ме све чека. Било је јако тешко, била сам дете, а преда мном су биле неке, за мене тада велике ствари – предузетништво кошта, режије коштају. Требало је испратити све то, и у почетку су то биле озбиљне финансијске кризе. Немаш подршку, немаш клијенте. Нисам имала ништа сем озбиљне визије испред себе и једне једине опције – а то је да морам да испливам, јер када си млад, ентузијазам те носи и идеш, ако треба, и грлом у јагоде”. Тек након седам година пословања дешава се прва прекретница. „Сплетом околности улазим у причу са женом која је лидер беаутy индустрије целе старе Југославије, женом која је била интернационални едукатор и неког ко је довео сајам Додир Париза у Србију, манифестацију која се и данас одржава, Јанина Миковић. Добила сам понуду да радим за њу, но, ја сам имала нешто друго на уму, као одговор, понудила сам јој партнерство. На крају, све је резултирало нашом заједничком фирмом”.
Испустити шансу није била опција, тако је Снежана од своје менторке, која је, како каже, била озбиљан стручњак и велики радохолик, упила све што је могла. <br /><br />
Оно што сматра да је издвојило на никад бржем и растућем тржишту лепоте јесте то што је ауторизовала своју методу рада и што је велики радохолик.
Управо ово види као кључ успеха – „Када посвећено радиш, то људи препознају, а са тим долази и оно, можда најважније – тако непогрешиво овладаш материјом!” <br /><br />
Одржати квалитет, такође, истиче као битно, јер чим квантитет замени квалитет ту прича мора да се заврши, сматра она.
Без „зицера”, без брзих решења за младост и лепоту која искачу из фрижидера, Снежана је својим услугама понудила нешто другачије – природну технику која бустује тонус чинећи га дугорочно виталнијим. Како каже „најлакше је данас нешто ископирати, а најтеже је остати свој и аутентичан”. Уз то, младим предузетницима саветује да се не плаше, већ да донесу одлуку, да фиксирају сопствену визију, а уколико и придодају напоран и посвећен рад, успех је загарантован.

            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineRedTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bxvIl3eHWhE?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
