import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineBlueOne from '../../assets/linebluefirst.png';
import LineBlueTwo from '../../assets/linebluesecond.png';
import MenuClose from '../../assets/menuclose.png';


export default function ArticleBlue() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

   useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={ArticleArtist} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineBlueOne} alt="" />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center red-text border-red position-relative'>
                <span className="article__title--lineblue position-absolute"></span>
                Inocent Diamont
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-2 red-text">
              Породица Ларе Стефановић се већ шест генерација бави производњом накита. То јој је био подстрек да у оквирима ове традиционалне индустрије покуша да креира нешто ново и другачије.
            </p>
            <p className='text-center mb-2 red-text'>
              Тако је настала златара Иноцент Диамонд, прва у региону која прави еколошки и етички накит.
            </p>
            <p className='red-text text-center'>
              „Еколошки дијаманти су хемијски, физички и оптички исти као и дијаманти који се копају из земље. Једина разлика је њихово порекло, односно процес производње где се симулирају природни услови у којима дијаманти настају“, истиче. Лара се надовезала на актуелне тенденције тржишта да ствари настају без уништавања природних ресурса. „Дизајн је наш, производња је наша, али сами дијаманти расту у лабораторији у Канади. Највећи изазов је едукација тржишта, да се људима објасни да то јесте прави дијамант, само бољи по наше окружење“, говори. „Имам обичај да кажем да је ово као лед, имате лед у замрзнутом језеру, имате лед у замрзивачу, на вама је да изаберете да ли ћете га направити или узети из природе“, додаје. Увести иновацију у индустрији које се вековима уназад готово уопште не мења представља ризик за себе, али и подстрек да се у нечему буде пионир, што је основа иновативног предузетништва. Стефановићева закључује како је клијентима важна еко компонента њених производа, као и чињеница да су приступачнији од традиционалних дијаманата.
            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineBlueTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/yiEJ3osoJxs?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
