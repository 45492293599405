import React, {useContext} from 'react';
import {ProductContext} from '../context/context';
import CloseMenu from '../assets/closemenu.png';
import Logo from '../assets/logored.png';
import SidebarLeft from '../assets/sidebarleft.png';
import SidebarRight from '../assets/sidebarright.png';
import BlueLine from '../assets/blueline.png';
import Fb from '../assets/facebook.png';
import Yt from '../assets/youtube.png';
import In from '../assets/instagram.png';
import HeroBg from '../assets/hero-bg.png';
import { Link } from "react-router-dom";



export default function Sidebar() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;

  const unlockSidebar = () => {
    document.body.classList.remove("body-sidebar");
    handleSidebar(false);
  }
  return (
    <div style={{background: `url(${HeroBg})`}} className={`sidebar ${sidebarOpen === true ? 'sidebar-show' : '' } `}>
       <div className='d-flex justify-content-between'>
            <div className="logo">
                <a href="/"><img src={Logo} alt="" /></a>
            </div>
            <div style={{zIndex:2, cursor:'pointer'}} onClick={() => unlockSidebar()} className="main-menu">
                <img className='close-menu' src={CloseMenu} alt="" />
            </div>
        </div>
        <ul className="sidebar__menu p-0">
          <li>
            <a onClick={() => unlockSidebar()} href="/#canjimarija">ЧАЊИ МАРИЈА</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()} href="/#jovanamarkovic">ЈОВАНА МАРКОВИЋ</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()} href="/#katarinavalterovic">КАТАРИНА ВАЛТЕРОВИЋ</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()} href="/#ljubicanjegomir">ЉУБИЦА ЊЕГОМИР</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()}  href="/#snezanalatic">СНЕЖАНА ЛАТИЋ</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()} href="/#draganamilanovic">ДРАГАНА МИЛАНОВИЋ</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()} href="/#sestrevitanov">СЕСТРЕ ВИТАНОВ</a>
          </li>
          <li>
            <a onClick={() => unlockSidebar()} href="/#minaveskovic">МИНА ВЕСКОВИЋ</a>
          </li>
        </ul>

        <div className="sidebar__social d-flex flex-column justify-content-center align-items-center">
          <div className="d-inline">
            <a href="https://www.instagram.com/znamdamogu/" target="_blank">
              <img style={{position:'relative', top:'17px'}} src={In} alt="" />
            </a>
            <a href="https://www.facebook.com/projekatznamdamogu/" target="_blank">
              <img src={Fb} alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCGd1xMzYr82F_zvrfXtrz3w/featured" target="_blank">
              <img style={{position:'relative', top:'5px'}} src={Yt} alt="" />
            </a>
          </div>
          <div>
            <img src={BlueLine} alt="" />
          </div>
        </div>
        <img src={SidebarLeft} alt="" className="sidebar__left" />
        <img src={SidebarRight} alt="" className="sidebar__right" />
    </div>
  )
}
