import React from 'react';
import AboutBg from '../assets/about-bg.png';
import AboutFigure from '../assets/aboutfigure.png';
import AboutTextBg from '../assets/abouttextbg.png'
import Logo from '../assets/logo.png';


export default function About() {
  return (
    <div className='about pt-5' style={{backgroundImage: `url(${AboutBg})`, marginTop:'-40px'}}>
        <div className="position-relative mt-3 about__container">
          <img className='about__textbg' src={AboutTextBg} alt="" />
          <div className="about__content position-absolute">
          <p className="text-center tfix">
           Програм подршке развоју и промоцији женског иновационог предузетништва подстиче жене да размишљају на предузетнички начин и оснују своје стартап компаније, или на други начин узму учешће у иновационом предузетништву.
          </p>
          <ul className='text-center d-flex legacy p-0'>
            <li className='mr-1'>
              <a href="https://znamdamogu.rs/2021/">2020  - 2021   </a>
            </li>
          </ul>
          <img className='about__logo d-block mx-auto' src={Logo} alt="" />
          </div>
          <img className='about__figure position-absolute' src={AboutFigure} alt="" />
        </div>
    </div>
  )
}
