import React from 'react';
import AvatarRed from '../assets/chanymaria.png';
import RedArrowLine from '../assets/redarrowline.png';
import SectionOneBg from '../assets/sectiononebg.png';
import RedTriangle from '../assets/redtriangle.png';
import RedUnder from '../assets/redunder.png';
import SectionOneFigure from '../assets/sectiononefigure.png';



export default function Section1() {
  return (
    <div id="canjimarija" className='section sectionone' style={{background:`url(${SectionOneBg})`}}>
      <div className="d-block d-lg-flex section__container">
        <div className="position-relative left section__redmargine">
          <img className='section__avatar position-relative' src={AvatarRed} alt="" style={{zIndex:2}} />
          <img src={RedUnder} alt="" className="section__under position-absolute" style={{zIndex:0}} />
        </div>
        <div className="position-relative right">
          <div className="section__title d-flex">
            <h2 className='red-text'>Прича из рукава</h2>
            <div className='section__figure d-none d-lg-block'>
              <img src={SectionOneFigure} alt="" />
            </div>
          </div>
          <p className="section__description red-text frubik">
            „CHANYMARIA” је модни бренд иза кога стоји уметница Марија Чањи. Statement комади, како то модни уредници воле да кажу, њен су заштитни знак. Сматра да свако ко носи њену гардеробу прича сопствену, али делом и њену причу. Поруке које емитују гломазни рукави капута, јакни и блејзера кажу: ја сам овде, ја сам снажна, ја то могу!
          </p>
          <div className="section__button position-relative">
            <button className='blue-bg'><a href="/chanymaria">ПРОЧИТАЈ ВИШЕ</a></button>
            <img className='section__triangle-red position-absolute' src={RedTriangle} alt="" />
            <img className='position-absolute section__under-red' src={RedArrowLine} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
