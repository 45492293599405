import React from 'react';
import AvatarRed from '../assets/sestrevitanov.png';
import RedArrowLine from '../assets/redarrowline.png';
import SectionThreeBg from '../assets/sectionthreebg.png';
import RedTriangle from '../assets/redtriangle.png';
import RedUnder from '../assets/redunder.png';
import SectionSevenFigure from '../assets/sectionsevenfigure.png';



export default function Section7() {
  return (
    <div id="sestrevitanov" className='section sectionseven' style={{background:`url(${SectionThreeBg})`}}>
      <div className="d-block d-lg-flex section__container">
        <div className="position-relative left section__redmargine">
          <img className='section__avatar position-relative' src={AvatarRed} alt="" style={{zIndex:2}} />
          <img src={RedUnder} alt="" className="section__under position-absolute" style={{zIndex:0}} />
        </div>
        <div className="position-relative right">
          <div className="section__title d-flex">
            <h2 className='red-text'>Поезија на кожи</h2>
            <div className='section__figure d-none d-lg-block'>
              <img src={SectionSevenFigure} alt="" />
            </div>
          </div>
          <p className="section__description red-text frubik">
            Иза бренда „Сестре Витанов” стоје Данијела и Марина, оне су трећа генерација у породици која се бави производњом накита. У промеру чега је више, уметности или занатства, рекло би се да уметност иде корак испред. Не зато што су им чекићи и остали фини алати страни, већ због слободе која је њихова основна стваралачка водиља, а зна се кога она води и, пре свега, инспирише.
          </p>
          <div className="section__button position-relative">
            <button className='blue-bg'><a href="/sestrevitanov">ПРОЧИТАЈ ВИШЕ</a></button>
            <img className='section__triangle-red position-absolute' src={RedTriangle} alt="" />
            <img className='position-absolute section__under-red' src={RedArrowLine} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
