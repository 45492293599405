import React from 'react';
import AvatarBlue from '../assets/jovanafond.png';
import BlueArrowLine from '../assets/bluearrowline.png';
import SectionTwoBg from '../assets/sectiontwobg.png';
import RedTriangle from '../assets/bluetriangle.png';
import BlueUnder from '../assets/blueunder.png';
import SectionTwoFigure from '../assets/sectiontwofigure.png';




export default function Section2() {
  return (
    <div id="jovanamarkovic" className='section sectiontwo' style={{background:`url(${SectionTwoBg})`}}>
      <div className="d-block d-lg-flex section__container">
        <div className="position-relative order-2 left section__bluemargine">
          <img className='section__avatar position-relative' src={AvatarBlue} alt="" style={{zIndex:2}} />
          <img src={BlueUnder} alt="" className="section__under--blue position-absolute" style={{zIndex:0}} />
        </div>
        <div className="position-relative right order-1">
          <div className="section__title d-flex">
            <h2 className='blue-text'>Underground живи</h2>
            <div className='section__figure d-none d-lg-block'>
              <img src={SectionTwoFigure} alt="" />
            </div>
          </div>
          <p className="section__description blue-text frubik">
            У времену у ком делује да је некадашњи underground потпуно нестао, право је освежење срести га, и то у лицу моде, поготово када се томе придода да је носилац идеје мама троје деце. Јована Марковић оснивач је модне продукцијске куће „Fond Fashion House”, својеврсног hub-а где мода „почиње”.
          </p>
          <div className="section__button position-relative">
            <button className='blue-bg'><a href="/fondfashionhouse">ПРОЧИТАЈ ВИШЕ</a></button>
            <img className='section__triangle-blue position-absolute' src={RedTriangle} alt="" />
            <img className='position-absolute section__under-blue' src={BlueArrowLine} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
