import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineBlueOne from '../../assets/linebluefirst.png';
import LineBlueTwo from '../../assets/linebluesecond.png';
import MenuClose from '../../assets/menuclose.png';
import Play from '../../assets/play.png';
import Mina from '../../assets/minaveskovic.jpg'



export default function ZvrljanjeSrcem() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

   useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={Mina} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineBlueOne} alt="" />
          <img src={Play} alt="" className='position-absolute playbutton' />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center red-text border-red position-relative'>
                <span className="article__title--lineblue position-absolute"></span>
                Жврљање срцем
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-0 px-2 red-text">
              Мина Весковић идејни је творац и оснивач креативне радионице и бренда „Жврља”. Њени custom made комади накита – огрлице, наруквице и привесци – аутентични су, пре свега, јер греју срца. Тако је све и почело, креаторка је пожелела да цртеж своје ћерке некако увек има код себе. <br /><br />
            </p>
            <p className='text-center mb-0 px-2 red-text'>
              Свака прича о бизнису има свој почетак, у причи о радионици „Жврља”, све заправо почиње заокретом. Наиме, професорка српског језика и књижевности није знала апсолутно ништа о бизнису, предузетништву, дизајну, маркетингу, а понајмање о креирању и изради накита, све док није пожелела да један од цртежа своје ћерке некако увек има при себи, а да он временом не постане похабани комад хартије. <br /><br />
            </p>
            <p className='red-text text-center px-2'>
              Тако се родила идеја о уникатном накиту, који су одмах, након што је настала прва огрлица, пожелеле мама, сестра, другарица и другарица другарице. Даљи ток се наметао сам – отворити радионицу која ће се бавити производњом накита на основу дечијих цртежа! То што је Мина из књига искочила право у радионицу, испоставиће се, како каже, као њена велика предност – „Нисам имала никакву баријеру да питам баш све што ме је занимало, до детаља, а то је тако велика ствар, да и када правите грешке не гледате на то као некакав минус, већ као нешто што вас унапређује, а морала сам да положим златарски испит и научим апсолутно све о златарском занату! Следећа лекција био је маркетинг који сматрам, можда, најважнијим сегментом, као и дилема како ћемо уопште представити ’Жврљу!’ свету. Често су ми постављали питање – какав ти је бизнис план – никакав, нисам га имала, и то је нешто што често саветујем младим предузетницама, не треба увек баш све испланирати, оно што је најважније јесте да кренеш, ствари ћеш решавати у ходу!” <br /><br />
              „Жврље” се израђују ручно, и свака понаособ изискује много сати. Прича о „Жврљи”, заиста је праћена спонтаном енергијом, јер, како каже, овај стартап пројекат поставила је групица људи из различитих области пословања. Наставило се тако да занимање професора српског језика није постало баласт и посао који је на чекању, већ прилика да вољена професија заживи на нов начин, у једном модерном, фенси оделу – „Storytelling сматрам изузетно важним за представљање производа, то је уједно и начин на који сам спојила ове две љубави”. На почетку, како истиче, није имала ништа, са личном уштеђевином, јасном идејом и без бизнис плана, стигла је до „Жврље”. <br /><br /> Шта то за клијенте у ствари значи?
              Да кад се случајно погледају у огледало, сете се, да су некоме звезда, срце, месец или џин.
            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineBlueTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/WtHnQlU4mpg?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
