import React, {useContext} from 'react';
import Logo from '../assets/logored.png';
import {ProductContext} from '../context/context';
import MenuOpen from '../assets/menyopen.png';



const NavBar = () => {

    const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;

    return (
        <div className='d-flex justify-content-between'>
            <div className="logo">
                <a href="/"><img src={Logo} alt="" /></a>
            </div>
            <div style={{zIndex:2, cursor:'pointer'}} onClick={() => handleSidebar()} className="main-menu">
                <img src={MenuOpen} alt="" />
            </div>
        </div>
     );
}




export default NavBar;