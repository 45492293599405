import React, {useState, useContext, useEffect} from 'react';
import NavBar from './NavBar';

import BlueLine from '../assets/blueline.png';
import HeroBg from '../assets/hero-bg.png';
import HeroBig from '../assets/hero-big.png';
import Triangle from '../assets/triangle.png';
import Scroll from '../assets/scroll.png';
import {ProductContext} from '../context/context';
import Sidebar from './Sidebar';
import Fb from '../assets/facebook.png';
import Yt from '../assets/youtube.png';
import In from '../assets/instagram.png';
import MenuClose from '../assets/menuclose.png';


export default function Header() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false)


  useEffect(() => {
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[sidebarOpen])

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='heroblock'>
        <div className="d-block d-md-block d-lg-flex">
          <div></div>
          <div className='header__text'>
            <h1 className='red-text'>
              ЗНАМ
              <span className='d-block blue-text'>
                ДА МОГУ
              </span>
            </h1>
            <div className="header__line blue-bg"></div>
            <p className='red-text mb-3'>
              Кабинет министра за иновације и технолошки развој већ трећу годину заредом подржава иновационе идеје предузетница кроз Програм подршке развоју и промоцији женског иновационог предузетништва спроводи пројекат „Знам да могу“ чији је циљ повећање удела жена у иновационом предузетништву.
            </p>
            <div className='position-relative'>
              <button onClick={()=> setOpen(true)} className='blue-bg header__button'>ПОГЛЕДАЈ ВИДЕО</button>
              <img className='header__triangle' src={Triangle} alt="" />
            </div>
          </div>
          <div className='header__image'>
          <img src={HeroBig} alt="" />
          </div>
        </div>
        <div className='scroll position-absolute'>
          <img src={Scroll} alt="" />
        </div>
        <div className="social-hero position-absolute">
          <div className="d-flex social-img">
            <a href="https://www.instagram.com/znamdamogu/" target="_blank">
              <img src={In} alt="" />
            </a>
            <a href="https://www.facebook.com/projekatznamdamogu/" target="_blank">
              <img src={Fb} alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCGd1xMzYr82F_zvrfXtrz3w/featured" target="_blank">
              <img src={Yt} alt="" />
            </a>
          </div>
          <img src={BlueLine} alt="" />
        </div>
      </div>
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/usoCYViUgzg?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
