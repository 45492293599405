import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineRedOne from '../../assets/lineredfirst.png';
import LineRedTwo from '../../assets/lineredsecond.png';
import MenuClose from '../../assets/menuclose.png';
import Katarina from '../../assets/katarinazmijanac.jpg';
import Play from '../../assets/play.png';


export default function ValterShoes() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

  useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={Katarina} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineRedOne} alt="" />
          <img src={Play} alt="" className='position-absolute playbutton' />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center blue-text border-blue position-relative'>
                <span className="article__title--linered position-absolute"></span>
                Мај нејм из Шуз, ВалтерШуз
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-0 red-text">
              Испред бренда „Valtershoes” на инстаграм профилу стоји опис – handmade shoes, designed and crafted in Belgrade. Иза овог бренда налази се млада дизајнерка и предузетница Катарина Валтеровић Змијанац, која је своју жељу из студентских дана претворила у бизнис.<br /><br />
            </p>
            <p className='text-center mb-0 red-text'>
              „Ко стоји иза ’Valtershoes-а’ и колико вас има”, најчешће је питање постављано предузетници и оснивачици поменутог бренда. „Само ја”, кратак је одговор дизајнерке, „име и производња идејно су потекли од мене”. Студирање модног дизајна и израда одеће од коже, довели су најпре до хобија, а данас и до правог бизниса.<br /><br />
            </p>
            <p className='red-text text-center'>
              Кожна, ручно прављена женска обућа, софистицираног дизајна, идеја је водиља овог hand craft бренда. Из мора high class-а и серијске производње, обућа „Valtershoes” издваја се фокусом на удобност и личнијим приступом онима којима се бренд „обраћа”. Катарина инспирацију налази у женама свих профила, како успешној и запосленој жени, тако и у студентктињи, а развој бренда од идеје до реализације праћен је годинама искуства у домаћим занатским радионицама. <br /><br />
Тако настају, не само „Valtershoes” ципеле, већ и жеља да се занатство сачува од изумирања и потпуног нестајања у све брже долазећим IT и сличним индустријама. Као feedback од својих муштерија, Катарина неретко добија и поклоне, што је, сматра, последица става бренда, да купци тачно знају ко, како и колико за њих производи.

            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineRedTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/i12bBpWIhEY?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
