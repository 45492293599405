import React from 'react';
import AvatarBlue from '../assets/zvrlja.png';
import BlueArrowLine from '../assets/bluearrowline.png';
import SectionTwoBg from '../assets/sectiontwobg.png';
import RedTriangle from '../assets/bluetriangle.png';
import BlueUnder from '../assets/blueunder.png';
import SectionEightFigure from '../assets/sectioneightfigure.png';



export default function Section8() {
  return (
    <div id='minaveskovic' className='section sectioneight' style={{background:`url(${SectionTwoBg})`}}>
      <div className="d-block d-lg-flex section__container">
        <div className="position-relative order-2 left section__bluemargine">
          <img className='section__avatar position-relative' src={AvatarBlue} alt="" style={{zIndex:2}} />
          <img src={BlueUnder} alt="" className="section__under--blue position-absolute" style={{zIndex:0}} />
        </div>
        <div className="position-relative right order-1">
          <div className="section__title d-flex">
            <h2 className='blue-text'>Жврљање срцем</h2>
            <div className='section__figure d-none d-lg-block'>
              <img src={SectionEightFigure} alt="" />
            </div>
          </div>
          <p className="section__description blue-text frubik">
            Мина Весковић идејни је творац и оснивач креативне радионице и бренда „Жврља”. Њени custom made комади накита – огрлице, наруквице и привесци – аутентични су, пре свега, јер греју срца. Тако је све и почело, креаторка је пожелела да цртеж своје ћерке некако увек има код себе.
          </p>
          <div className="section__button position-relative">
            <button className='blue-bg'><a href="/zvrljanjesrcem">ПРОЧИТАЈ ВИШЕ</a></button>
            <img className='section__triangle-blue position-absolute' src={RedTriangle} alt="" />
            <img className='position-absolute section__under-blue' src={BlueArrowLine} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
