import React, {useState, useContext, useEffect} from 'react';
import NavBar from '../../components/NavBar';
import Sidebar from '../../components/Sidebar';
import { ProductContext } from '../../context/context';
import HeroBg from '../../assets/hero-bg.png';
import ArticleArtist from '../../assets/articleartist.jpg';
import ArticleFigureOne from '../../assets/articlefigure1.png';
import ArticleFigureTwo from '../../assets/articlefigure2.png';
import Circle from '../../assets/circle.png';
import LineBlueOne from '../../assets/linebluefirst.png';
import LineBlueTwo from '../../assets/linebluesecond.png';
import MenuClose from '../../assets/menuclose.png';
import Play from '../../assets/play.png';
import Jovana from '../../assets/jovanamarkovic.jpg'


export default function FashionFondHouse() {
  const context = useContext(ProductContext);
  const { sidebarOpen, handleSidebar } = context;
  const [isOpen, setOpen] = useState(false);


  useEffect(() => {
    let articleshow = document.getElementsByClassName('article__block');
    let figureone = document.getElementsByClassName('article__figureone');
    let figuretwo = document.getElementsByClassName('article__figuretwo');
    if(sidebarOpen === true) {
      document.body.classList.add('body-sidebar');
      articleshow[0].style.display='none';
      figureone[0].style.display='none';
      figuretwo[0].style.display='none';


    } else {
      document.body.classList.remove("body-sidebar");
      articleshow[0].style.display='block';
      figureone[0].style.display='block';
      figuretwo[0].style.display='block';

    }
  },[sidebarOpen])

  useEffect(() => {
    let footer = document.getElementsByClassName('footer');
    footer[0].style.marginTop=0;

    let header = document.getElementsByClassName('header');
    header[0].style.paddingBottom="110px"
  },[])

   useEffect(() => {
    if(isOpen === true) {
      document.body.classList.add('body-sidebar');
    } else {
      document.body.classList.remove("body-sidebar");
    }
  },[isOpen])


  return (
    <div className='header article position-relative"' style={{backgroundImage:`url('${HeroBg}')`}}>
      <NavBar />
      <Sidebar  />
      <div className='article__block'>
        <div className="article__artist position-relative" onClick={()=> setOpen(true)}>
          <img style={{zIndex:22, position:'relative', cursor:'pointer'}} src={Jovana} alt="" />
          <img className='article__circle position-absolute' src={Circle} alt="" />
          <img className='article__linebluefirst position-absolute' src={LineBlueOne} alt="" />
          <img src={Play} alt="" className='position-absolute playbutton' />
        </div>
        <div className="article__box red-box-border position-relative" style={{zIndex:2}}>
          <div className="article__wrap position-relative">
            <div className="article__title  p-2 mb-5">
              <h1 className='text-center red-text border-red position-relative'>
                <span className="article__title--lineblue position-absolute"></span>
                Underground живи
              </h1>
            </div>
            <p className="font-weight-bold text-center mb-0 red-text px-2">
              У времену у ком делује да је некадашњи underground потпуно нестао, право је освежење срести га, и то у лицу моде, поготово када се томе придода да је носилац идеје мама троје деце. Јована Марковић оснивач је модне продукцијске куће „Fond Fashion House”, својеврсног hub-а где мода „почиње”. <br /><br />
            </p>
            <p className='text-center mb-0 red-text px-2'>
              Можда делује збуњујуће, ствар је више него конкретна – „млади дизајнери обично немају додирних тачака ни са чим осим са својим креацијама, а понајмање са производњом и даљим процесом пословања”, констатује Јована. <br /><br />
            </p>
            <p className='red-text text-center px-2'>
              „Fond Fashion House” је, заправо, consulting где дизајнери могу заокружити целу причу, од скице до производње, или макар добити информације о процесу. Јована која је по струци моделар, напомиње да своје знање несебично дели и да је на идеју дошла и због тога што су је ужасно нервирали дизајнери који своје шиваче крију као некакав Свети грал. Са дугогодишњим искуством у области моде, пожелела је да понуди нешто што у Србији званично још увек не постоји и на тај начин отвори врата свима који желе да се баве модом. <br /><br />
              Њен мото је висока концентрисаност на циљ, уз динамику „go with a flow”, и каже да ју је та комбинација увек непогрешиво водила тамо где треба. Тако је њена првобитна модна прича кренула из underground радње Palma’s, коју је водила са још неколицином сарадника. Како ту није видела перспективу, решава да оде корак даље. <br /><br />
              Не желећи да одступи од себе и напусти underground vibe, нити да потпуно склизне у комерцијалу, савршено решење налази у „Fond Fashion House -у”. „Овде сам комуникациона спона између дизајнера и шивача. Консултанткиња вољна да са клијентима подели своја знања”. <br /><br />
              Оно што јој, такође, даје мотивацију за даљи напредак је пркос, првенствено усмерен на одупирање доминацији маркетинга над квалитетом и суштином производа, као и пркос идеји да жена мора да стигне баш сваку од „хиљаду” обавеза које јој намеће дан.
              С обзиром на то да је мама троје деце, јасно је да овакав став изискује, најблаже речено, одлучност. Тренутно се бори са недостатком шивача, јер занатски послови, како каже, код нас изумиру, а у наредним корацима себе види како ради овај посао за неке од истакнутих светских имена.

            </p>
          </div>
        </div>
        <img className='article__linebluesecond' src={LineBlueTwo} alt="" />
      </div>
      <img className='article__figureone position-absolute' src={ArticleFigureOne} alt="" />
      <img className='article__figuretwo position-absolute' src={ArticleFigureTwo} alt="" />
      {
        isOpen &&
        <div className="video-modal">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/EHY3JFt7_DY?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <div className="close-modal" onClick={() => setOpen(false)}>
            <img src={MenuClose} alt="" />
          </div>
        </div>
      }
    </div>
  )
}
